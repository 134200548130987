.container {
  text-align: center;
  padding: 60px 20px; /* Padding을 추가해 더 넓은 공간 확보 */
  font-family: "Arial", sans-serif;
  background-color: #f4f4f9;
  color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 2.5em; /* 글씨 크기 확대 */
  margin-bottom: 30px; /* 간격 확대 */
  color: #2c3e50;
  font-weight: bold;
}

p {
  font-size: 1.2em;
  margin: 15px 0;
  color: #34495e;
}

.logout-button {
  background-color: #2980b9; /* 색상 통일 */
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 8px; /* 모서리 둥글게 */
  cursor: pointer;
  margin-top: 20px; /* 간격 확대 */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-button:hover {
  background-color: #1f639a; /* 호버 시 색상 변경 */
  transform: translateY(-3px); /* 호버 시 약간 위로 이동 */
}

.links {
  margin-top: 50px; /* 상단 간격 확대 */
  display: flex;
  justify-content: center;
  gap: 20px; /* 버튼 간 간격 확대 */
  width: 100%;
  max-width: 600px; /* 컨테이너의 최대 너비를 제한 */
}

.link {
  color: white;
  text-decoration: none;
  padding: 15px 25px; /* 버튼 크기 조절 */
  border-radius: 10px;
  background-color: #2980b9;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: center;
}

.link:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* 호버 시 더 강한 그림자 */
  background: linear-gradient(135deg, #8e44ad, #3498db);
}

input,
select,
button {
  width: 100%;
  max-width: 400px; /* 입력 필드의 최대 너비 확대 */
  padding: 12px;
  margin: 15px 0; /* 간격 확대 */
  border: 1px solid #ccc;
  border-radius: 8px; /* 모서리 둥글게 */
}

input:focus,
select:focus,
button:focus {
  outline: none;
  border-color: #2980b9; /* 포커스 시 색상 통일 */
}

.message {
  color: #e74c3c;
  margin-top: 15px; /* 간격 확대 */
  font-weight: bold;
}

/* 반응형 미디어 쿼리 */
@media (max-width: 768px) {
  .container {
    padding: 30px;
  }

  h1 {
    font-size: 2.2em;
  }

  .links {
    flex-direction: column; /* 작은 화면에서 세로 배치 */
  }

  .link {
    max-width: 300px;
  }
}

