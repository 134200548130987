@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");

body {
  font-family: "Noto Sans KR", sans-serif;
  background-color: #f0f4f8;
  color: #333;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #1a73e8;
  text-align: center;
}

button {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #155ab6;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  margin: 0 5px;
}

.my-page,
.search-assistant {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input,
select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input:focus,
select:focus {
  border-color: #1a73e8;
  outline: none;
}

button.logout {
  background-color: #d9534f;
}

button.logout:hover {
  background-color: #c9302c;
}

button.deactivate {
  background-color: #f0ad4e;
  color: #fff;
  margin-top: 30px;
}

button.deactivate:hover {
  background-color: #ec971f;
}

.message {
  color: #d9534f;
}

h3 {
  color: #1a73e8;
}

a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
